import { css } from '@emotion/react';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';
import { ImpacterResponse } from '../../../../types/ImpacterTypes';
import { useMediaQuery } from '../../../../utils/hooks/useMediaQuery';
import { breakpointQueries, breakpoints, colors, spacing } from '../../../../utils/styleguide';
import IconButton from '../../buttons/IconButton';
import Typography from '../../text/Typography';
import ColorComponent from '../../types/ColorComponent';
import { Col, Row } from '../12ColGridLayout';
import CarouselSlider from './CarouselSlider';

const DesktopCardWidth = 357;
const MobileCardWidth = 269;

const ImpacterListWrapper = styled.div`
  max-width: none;
  overflow: hidden;
  padding: ${spacing[10]}px 0 ${spacing[10]}px;
  ${breakpoints.tablet} {
    padding: ${spacing[11]}px 0 ${spacing[10]}px;
  }
`;

const HeaderBlock = styled.div`
  padding: 0 0 ${spacing[6]}px;
  ${breakpoints.tablet} {
    padding: 0 0 ${spacing[8]}px;
  }
`;

function prioritizeImpacters(
  impacters: ImpactersSectionImpacter[],
  prioritizedImpacterIds?: number[],
) {
  if (!prioritizedImpacterIds) return impacters;
  const nonPrioritizedImpacters = [...impacters];

  const prioritizedImpacters = prioritizedImpacterIds.reduce<ImpactersSectionImpacter[]>(
    (acc, id) => {
      const prioritizedImpacterIndex = nonPrioritizedImpacters.findIndex(
        (impacter) => impacter.id === id,
      );
      if (prioritizedImpacterIndex > -1) {
        const newArray = [...acc, nonPrioritizedImpacters[prioritizedImpacterIndex]];
        nonPrioritizedImpacters.splice(prioritizedImpacterIndex, 1);
        return newArray;
      }
      return acc;
    },
    [],
  );

  return prioritizedImpacters.concat(nonPrioritizedImpacters);
}

export type ImpactersSectionImpacter = Pick<
  ImpacterResponse,
  'id' | 'name' | 'organisationName' | 'image'
>;

export type ImpactersSectionProps = {
  impacters?: ImpactersSectionImpacter[];
  prioritizedImpacterIds?: number[];
};

type ImpacterListLayoutProps = {
  Title: ColorComponent;
  description?: JSX.Element | string;
  impacters: ImpactersSectionImpacter[];
  help?: JSX.Element;
  prioritizedImpacterIds?: number[];
};

const ImpacterListLayout = ({
  Title,
  description,
  impacters,
  help,
  prioritizedImpacterIds,
}: ImpacterListLayoutProps) => {
  const [isTablet] = useMediaQuery(breakpointQueries.tablet);
  const cardWidth = isTablet ? DesktopCardWidth : MobileCardWidth;
  const cardBorder = isTablet ? 32 : 24;

  const [impactersScrolled, setImpactersScrolled] = useState(1);
  const sliderRef = useRef({} as HTMLDivElement);

  const reorderedImpacters = useMemo(
    () => prioritizeImpacters(impacters, prioritizedImpacterIds),
    [impacters, prioritizedImpacterIds],
  );

  const handleCarouselScroll = debounce(() => {
    const { scrollLeft = 0, scrollWidth = 1 } = sliderRef.current || {};
    const scrolledPercent = (scrollLeft + cardWidth / 2) / scrollWidth;
    setImpactersScrolled(Math.floor(scrolledPercent * impacters.length));
  }, 50);

  const scrollTo = (dir: 'next' | 'prev') => {
    const diff = dir === 'next' ? 1 : -1;
    return () => {
      sliderRef.current.scroll({
        left: (cardWidth + cardBorder) * (impactersScrolled + diff),
        behavior: 'smooth',
      });
    };
  };

  return (
    <ImpacterListWrapper>
      <HeaderBlock>
        <Row>
          <Col
            tablet="span 10"
            css={css`
              margin: 0 0 ${spacing[5]}px;
            `}
          >
            <Title color={colors.black} />
          </Col>
          <Col tablet="span 8">{description}</Col>
        </Row>
      </HeaderBlock>
      <CarouselSlider
        desktopCardWidth={DesktopCardWidth}
        mobileCardWidth={MobileCardWidth}
        impacters={reorderedImpacters}
        onScroll={handleCarouselScroll}
        ref={sliderRef}
      />
      <Row>
        <Col
          css={{
            textAlign: 'center',
          }}
        >
          <IconButton
            iconName="arrow-left"
            ariaLabel="arrow left"
            variant="onlyIcon"
            css={{ display: 'inline-block', cursor: 'pointer' }}
            onClick={scrollTo('prev')}
          />
          <IconButton
            iconName="arrow-right"
            ariaLabel="arrow right"
            variant="onlyIcon"
            css={{ marginLeft: 45, display: 'inline-block', cursor: 'pointer' }}
            onClick={scrollTo('next')}
          />
          <div
            css={{
              marginTop: spacing[4],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Col tablet="span 8">
              {typeof help === 'string' ? <Typography variant="detail">{help}</Typography> : help}
            </Col>
          </div>
        </Col>
      </Row>
    </ImpacterListWrapper>
  );
};

export default ImpacterListLayout;
