import { TypeLandingPage } from '../../../../@types/generated';

type Props = {
  page: TypeLandingPage;
  settings?: Record<string, unknown> | null;
};

const pageToCompatibleProps = ({ page, settings }: Props) => {
  const { affiliateName, affiliateDetails } = page.fields;
  return {
    affiliateName: affiliateName || (settings?.affiliateName as string | undefined),
    affiliateDetails,
    initiator: getInitiator({ page, settings }),
    campaignTag: page.fields.campaignTag || (settings?.tag as string | undefined),
    prioritizedImpacterIds:
      page.fields.prioritizedImpacterIds?.map((id) => Number(id)) ||
      (settings?.prioritizedImpacterIds as number[]),
    donationProgramId: page.fields.donationProgramId,
  };
};

const getInitiator = ({ page, settings }: Props) =>
  (settings?.initiator as string) ||
  page.fields.donationInitiator ||
  page.fields.tags?.join(' ') ||
  page.fields.slug;

export default pageToCompatibleProps;
