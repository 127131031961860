import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { EventHandler, forwardRef, UIEvent, useRef } from 'react';
import { breakpoints, colors, spacing } from '../../../../utils/styleguide';
import { sendGTMEvent } from '../../../../utils/tracking/gtm/sendGTMEvent';
import Icon from '../../Icon';
import Image from '../../Image';
import Typography from '../../text/Typography';
import { ImpactersSectionImpacter } from './ImpacterListLayout';

type CardContainerProps = {
  desktopCardWidth: number;
  mobileCardWidth: number;
};

const CardContainer = styled.a`
  display: block;
  min-width: ${(p: CardContainerProps) => p.mobileCardWidth}px;
  max-width: ${(p: CardContainerProps) => p.mobileCardWidth}px;
  margin: 0 ${spacing[2]}px;
  box-sizing: border-box;
  box-shadow: 2px 8px 16px rgba(10, 29, 82, 0.12);
  overflow: hidden;
  text-decoration: none;
  ${breakpoints.tablet} {
    margin: 0 ${spacing[3]}px;
    min-width: ${(p: CardContainerProps) => p.desktopCardWidth}px;
    max-width: ${(p: CardContainerProps) => p.desktopCardWidth}px;
  }
`;

type CardProps = {
  desktopCardWidth: number;
  mobileCardWidth: number;
};

const Card = styled.div`
  position: relative;
  width: 100%;
  max-width: ${(p: CardProps) => p.mobileCardWidth}px;
  height: ${(p: CardProps) => p.mobileCardWidth * 1.2}px; // 4:5
  background: ${colors.black};
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${breakpoints.tablet} {
    max-width: ${(p: CardProps) => p.desktopCardWidth}px;
    height: ${(p: CardProps) => p.desktopCardWidth}px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.9) 100%);
`;

const BelowCardText = styled.div`
  margin: ${spacing[3]}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const imageStyles = css`
  width: 48px;
  height: 48px;
  object-fit: cover;
  z-index: 1;
  border-radius: 50%;
  background-color: ${colors.black};
  color: ${colors.white};
  margin-right: ${spacing[2]}px;
  ${breakpoints.tablet} {
    width: 60px;
    height: 60px;
  }
`;

const ImpacterProfileInfo = styled.div`
  flex: 1;
  min-width: 50%;
  height: 60px;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${breakpoints.tablet} {
    height: auto;
  }
`;

const ImpacterInfoBlock = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${breakpoints.tablet} {
    max-width: 250px;
    overflow-wrap: break-word;
  }
`;

const SnapContainer = styled.div`
  scroll-snap-align: start;
  scroll-margin-left: ${spacing[2]}px;
  ${breakpoints.tablet} {
    scroll-margin-left: ${spacing[3]}px;
  }
`;

type ImpacterCardProps = {
  id: number;
  name?: string;
  imageUrl?: string;
  organisationName?: string;
  catchPhrase?: string;
  desktopCardWidth: number;
  mobileCardWidth: number;
};

const ImpacterCard = ({
  id,
  name,
  imageUrl,
  organisationName,
  mobileCardWidth,
  desktopCardWidth,
}: ImpacterCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <SnapContainer ref={ref}>
      <CardContainer
        onClick={() =>
          sendGTMEvent('productClick', {
            donationItemType: 'Impacter',
            items: [{ id, name, category: organisationName }],
          })
        }
        href={`/impacters/${id}`}
        target="_blank"
        rel="noopener noreferrer"
        desktopCardWidth={desktopCardWidth}
        mobileCardWidth={mobileCardWidth}
      >
        <Card desktopCardWidth={desktopCardWidth} mobileCardWidth={mobileCardWidth}>
          <Overlay />
        </Card>
        <BelowCardText>
          {imageUrl && <Image alt={name} url={imageUrl} css={imageStyles} lazyLoad />}
          <ImpacterProfileInfo>
            <ImpacterInfoBlock variant="bodyBold">{name}</ImpacterInfoBlock>
            <ImpacterInfoBlock variant="detail" color={colors.darkGrey}>
              {organisationName}
            </ImpacterInfoBlock>
          </ImpacterProfileInfo>
          <Icon stroke={colors.black} name="arrow-right" size={21} />
        </BelowCardText>
      </CardContainer>
    </SnapContainer>
  );
};

const OuterWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 ${spacing[1]}px ${spacing[4]}px;
  ::-webkit-scrollbar {
    display: none;
  }
  ${breakpoints.tablet} {
    padding: 0 ${spacing[7]}px ${spacing[6]}px;
  }
`;

interface CarouselSliderProps {
  impacters: ImpactersSectionImpacter[];
  onScroll: EventHandler<UIEvent<HTMLElement>>;
  desktopCardWidth: number;
  mobileCardWidth: number;
}

const CarouselSlider = forwardRef<HTMLDivElement, CarouselSliderProps>(function CarouselSlider(
  { impacters, onScroll, desktopCardWidth, mobileCardWidth },
  ref,
) {
  return (
    <OuterWrapper ref={ref} onScroll={onScroll}>
      {impacters.map(({ id, name, organisationName, image }) => (
        <ImpacterCard
          key={id}
          id={id}
          name={name}
          imageUrl={image?.file?.url}
          organisationName={organisationName}
          desktopCardWidth={desktopCardWidth}
          mobileCardWidth={mobileCardWidth}
        />
      ))}
    </OuterWrapper>
  );
});

export default CarouselSlider;
