// Returns a function that will replace
// all occurrences of "merge tags" with
// values for the provided object
//
// Example:
//
// const scope = { followers: 7, name: "John" };
// const replacer = createTemplateReplacer(scope);
//
// replacer('Hi *|name|*, you have *|followers|* followers)
//
// would return:
// 'Hi John, you have 7 followers
export function createTemplateReplacer(
  scope?: Record<string, string | number | undefined>,
): (text: string) => string {
  if (!scope || Object.keys(scope).length === 0) return (text) => text;
  return (text) => {
    const replaced = text.replace(/\*\|(\S*)\|\*/g, (_, p1) => {
      return scope[p1]?.toString() || '';
    });
    return replaced;
  };
}
