import { cdnClient } from '../../utils/galaxyClient';
import getSettingsFromSection from '../../utils/getSettingsFromSection';
import ImpacterListLayout, {
  ImpactersSectionProps,
} from '../component-library/layout/ImpacterList/ImpacterListLayout';
import SectionBackground from '../component-library/SectionBackground';
import { TemplatingContext } from './contexts/TemplatingContext';
import { PageSectionComponent } from './pageSections';
import RichText from './RichText/RichText';
import { createTemplateReplacer } from './util/mergeTagsTemplates';
import pageToCompatibleProps from './util/pageToCompatibleProps';
import sectionToCompatibleProps from './util/sectionToCompatibleProps';

const ImpactersSection: PageSectionComponent<ImpactersSectionProps> = ({
  section,
  serverSideProps: { impacters = [], prioritizedImpacterIds },
}) => {
  const { id, title, description, backgroundColor, help } = sectionToCompatibleProps({ section });

  /** Change the content in the help text to include the number of impacters **/
  const scope = { impacters: impacters.length };
  const templateReplacer = createTemplateReplacer(scope);

  return (
    <SectionBackground id={id} backgroundColor={backgroundColor}>
      <TemplatingContext.Provider
        value={{
          ...scope,
          templateReplacer,
        }}
      >
        <ImpacterListLayout
          Title={({ color }) => <RichText document={title} textColor={color} />}
          description={<RichText document={description} />}
          help={<RichText document={help} />}
          impacters={impacters}
          prioritizedImpacterIds={prioritizedImpacterIds}
        />
      </TemplatingContext.Provider>
    </SectionBackground>
  );
};

ImpactersSection.getCustomServerSideProps = async ({ page, section }) => {
  const settings = getSettingsFromSection(section);
  const { campaignTag, prioritizedImpacterIds } = pageToCompatibleProps({
    page,
    settings,
  });

  const {
    data: { result: impacters },
  } = await cdnClient().content.GET('/v2/impacters/', {
    params: {
      query: {
        anyTagName: campaignTag ? [campaignTag] : undefined,
      },
    },
  });

  return { impacters, prioritizedImpacterIds };
};

export default ImpactersSection;
